module.exports = [
  {
    name: 'Sendero Laguna Negra',
    style: 'green',
    image: 'fluvial.jpg',
    duration: '2:30 Horas',
    departure_times: '9:00 am, 10:00 am y 2:00 pm',
    difficulty: 'Media. Actividad para mayores de 7 años',
    description:
      'Hacer en kayak o en bote con motor este actividad dura aprox. 2:30 Horas, se puede nadar en la laguna y es perfecto para observación de aves flora y fauna',
    includes: 'Se puede nadar en la laguna y es perfecto para observación de aves flora y fauna',
  },
  {
    name: 'Sendero Rio Guaviare',
    style: 'white',
    image: 'rio-guaviare.jpeg',
    duration: '2:30 Horas',
    departure_times: '9:00 am, 10:00 am y 2:00 pm',
    difficulty: 'Media. Actividad para mayores de 7 años',
    description:
      'caminata por medio de bosque a Amazonico inundable aprox. 1 kilometro de largo hasta llegar al Rio Guaviare se pueden observar primates y aves de playa a la orilla del Rio Guaviare ademas de pasar por cultivos de cacao que en su momento remplazaron la coca en esta zona',
    includes:
      'Se pueden observar primates y aves de playa a la orilla del Rio Guaviare ademas de pasar por cultivos de cacao que en su momento remplazaron la coca en esta zona',
  },
  {
    name: 'Finca el paraíso, cascada arcoíris y la guacada',
    style: 'green',
    image: 'cascada-arcoiris.jpg',
    duration: 'Tiempo aprox ruta 2.30 Horas',
    difficulty: 'Dificultad baja',
    description: '15 kilómetros vía terrestre',
    includes: '',
  },
  {
    name: 'Puentas naturales',
    style: 'white',
    image: 'puentes-naturales.jpg',
    duration: 'Tiempo aprox ruta 2 horas',
    difficulty: 'Dificultad baja',
    description: '5 kilómetros vía terrestre',
    includes: '',
  },

  {
    name: 'Cerro pinturas ',
    style: 'green',
    image: 'cerro-pinturas .png',
    duration: 'Tiempo aprox ruta 3.5 horas/3.37 km',
    difficulty: 'Dificultad media',
    description:
      'Arte rupestre 12.000 años de antigüedad la puerta del chibiriquete, Recomendaciones: Llevar linterna para cruzar la cueva que mide casi 300 metros de largo.',
    includes: 'Vereda cerro azul.',
  },
  {
    name: 'Nuevo Tolima  ',
    style: 'white',
    image: 'nuevo-tolima.png',
    duration: 'Tiempo aprox ruta 5 horas/ 35 kilómetros vía terrestre',
    difficulty: 'Dificultad media',
    description: 'Arte rupestre, serranía la lindosa, 12.000 años de antigüedad',
    includes:
      'Arte rupestre y se puede complementar la ruta con 1 de estos atractivo posos naturales, túneles naturales o ciudad de piedra según la temporada',
  },
  {
    name: 'Raudal del guayabero',
    style: 'green',
    image: 'raudal-guayabero.jpg',
    duration: 'Tiempo aprox ruta.2 horas 1.5 km',
    difficulty: 'Dificultad media',
    description:
      '37 kilómetros por vía terrestre, 1.30 por rio Guaviare y guayabero, serranía la lindosa, raudal angosturas 2, vereda el raudal',
  },
  {
    name: 'ciudad de piedra',
    style: 'white',
    image: 'ciudad-piedra.png',
    duration: 'Tiempo aprox ruta 1.30 horas',
    difficulty: 'Dificultad baja',
    description: '15 kilómetros vía terrestre',
  },
  {
    name: 'túneles naturales',
    style: 'green',
    image: 'tuneles-naturales.jpg',
    duration: 'Tiempo aprox ruta 2.30 horas',
    difficulty: 'Dificultad baja',
    description: '11 kilómetros vía terrestre',
  },
  {
    name: 'posos naturales',
    style: 'white',
    image: 'tuneles-naturales.jpg',
    duration: 'Tiempo aprox ruta 2 horas',
    difficulty: 'Dificultad baja',
    description: '8 kilómetros vía terrestre',
  },
  {
    name: 'Puerta de Orión',
    style: 'green',
    image: '',
    duration: 'Tiempo aprox ruta 1.30 horas',
    difficulty: 'Dificultad baja',
    description: '8 kilómetros vía terrestre ',
  },
  {
    name: 'Caño sabana algas rosadas. Tranquilandia',
    style: 'green',
    image: '',
    duration: 'Tiempo aprox ruta 2 horas',
    difficulty: 'Dificultad baja',
    description: '9 kilómetros vía terrestre ',
  },
  {
    name: 'Cascada las delicias',
    style: 'white',
    image: 'cascada-arcoiris.jpg',
    duration: 'Tiempo aprox ruta 2 horas',
    difficulty: 'Dificultad baja',
    description: '17 kilómetros vía terrestre',
  },
  {
    name: 'Finca el paraíso, cascada arcoíris y la guacada',
    style: 'green',
    image: 'cascada-arcoiris.jpg',
    duration: 'Tiempo aprox ruta 2.30 horas',
    difficulty: 'Dificultad baja',
    description: '15 kilómetros vía terrestre',
  },
  {
    name: 'Puentas naturales',
    style: 'white',
    image: 'puentes-naturales.jpg',
    duration: 'Tiempo aprox ruta 2 horas',
    difficulty: 'Dificultad baja',
    description: '5 kilómetros vía terrestre',
  },
];
