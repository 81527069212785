import React from 'react';
import wrappers from '../../config/trails';
import { useStaticQuery, graphql } from 'gatsby';

const useTrailsImg = () => {
  const data = useStaticQuery(graphql`
    {
       allFile(filter: {relativeDirectory: {eq: "trails"}}) {
        nodes {
          childImageSharp {
            sizes {
              srcWebp
              presentationWidth
              presentationHeight
              originalName
            }
          }
        }
      }
    }
  `);
  return data;
};

const Wallpaper = () => {
  const {allFile} = useTrailsImg();

  const  findNameImage = (name) => {
    const imageTrails = allFile.nodes.find((rows) =>(rows.childImageSharp.sizes.originalName  == name));
    return imageTrails.childImageSharp.sizes.srcWebp
  }

  return (
    <>
      {wrappers.map((row, i) => (
        <>
          <section id={row.name + i} className={`wrapper trails ${row.style}`}>
            <header>
              <h2>{row.name}</h2>
            </header>
            <div className="container">
              <div className="content wow animate__an imated animate__bounce animate__zoomIn">
                <div className="container_image">
                  {row.image ? (
                    <img
                      src={findNameImage(row.image)}
                      alt={`icon-${row.name}`}
                    />
                  ) : (
                    <img src={null} alt={`icon-${row.name}`} />
                  )}
                </div>
                <div className="container_text">
                  <p>
                    <span>Duración: </span>
                    {row.duration}
                  </p>
                  <p>
                    <span>Horarios de Salida: </span>
                    {row.departure_times}
                  </p>
                  <p>
                    <span>Dificultad: </span>
                    {row.difficulty}
                  </p>
                  <p>
                    <span>Descripción: </span>
                    {row.description}
                  </p>
                  {row.includes && (
                    <p>
                      <span>Incluye: </span>
                      {row.includes}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      ))}
    </>
  );
};

export default Wallpaper;
